@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    font-family: "DM Sans", sans-serif;
    scroll-behavior: smooth;
}
@media screen and (min-width: 1024px) {
    .spinfree:before {
        content: url("https://ratchet-depot-uploads.s3.amazonaws.com/Patented_Revolutionizing_the_Ratchet_Strap_Industry_Bold_242x242_5c4a52d6d3.png?updated_at=2022-11-15T02:18:24.159Z");
        position: absolute;
        width: 195px;
        height: 201px;
        top: -170px;
        right: 10px;
        opacity: 0.4;
    }
}

#homepage-featured-products {

}

.container {
    margin-left: auto;
    margin-right: auto;
}

.wysiwyg td {
    vertical-align: center !important;
}

.wysiwyg h2 {
    font-family: Roboto Condensed, sans-serif;
    font-size: 40px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
}

#topProductsScroller{
    scroll-behavior: smooth;
}
